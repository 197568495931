import React, { useState, useEffect } from 'react'
import useStyles from './styles'
import { TextField, Button, Typography, Paper } from '@material-ui/core';
import FileBase from 'react-file-base64'
import { useDispatch, useSelector } from 'react-redux'
import { createPost, updatePost } from '../../actions/posts';

function Form({ currentId, setCurrentId }) {

  const user = JSON.parse(localStorage.getItem('profile'));
  const [postData, setPostData] = useState({
    title: '',
    message: '',
    tags: '',
    selectedFile: ''
  })
  const classes = useStyles();
  const dispatch = useDispatch();
  const post = useSelector((state) => (currentId ? state.posts.find((p) => p._id === currentId) : null));

  useEffect(() => {
    if (post) setPostData(post);
  }, [post])

  const handleSubmit = (e) => {
    e.preventDefault();

    if (currentId) {
      dispatch(updatePost(currentId, {...postData, name: user?.result?.name}));

    } else {

      dispatch(createPost({...postData, name: user?.result?.name}));
    }
    clear();


  }
  const clear = () => {
    setCurrentId(null);
    setPostData({
    title: '',
    message: '',
    tags: '',
    selectedFile: ''});
  }

  if(!user?.result?.name)
  {
    return(
      <Paper className={classes.paper}>
        <Typography variant='h6' align='center' >
          please sign in to post your code snippets or bugs
        </Typography>
      </Paper>
    )
  }

  return (
    <Paper className={classes.paper}>
      <form autoComplete='off' noValidate className={`${classes.root} ${classes.form}`} onSubmit={handleSubmit}>
        <Typography variant='h6' > { currentId ? ` Edit `: ` Today's`} Code</Typography>
        <TextField name='title' variant='outlined' label='Title' fullWidth value={postData.title} onChange={(e) => setPostData({ ...postData, title: e.target.value })}></TextField>
        <TextField name='message' variant='outlined' label='Message' fullWidth value={postData.message} onChange={(e) => setPostData({ ...postData, message: e.target.value })}></TextField>
        <TextField name='tags' variant='outlined' label='Tags' fullWidth value={postData.tags} onChange={(e) => setPostData({ ...postData, tags: e.target.value.split(',') })}></TextField>
        <div className={classes.fileInput}>
          <FileBase type='file' multiple={false} onDone={({ base64 }) => setPostData({ ...postData, selectedFile: base64 })} />
        </div>
        <Button className={classes.buttonSubmit} variant='contained' color='primary' size="large" type='submit' fullWidth >Post Code</Button>
        <Button variant='contained' color='secondary' size="small" onClick={clear} fullWidth >Clear</Button>

      </form>
    </Paper>
  )
}

export default Form